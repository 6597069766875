import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faBell, faUser } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light d-inline-flex justify-content-evenly  d-sm-flex justify-content-sm-between px-4 py-3">
        <a
          className="navbar-brand"
          href="#."
          onClick={(e) => {
            e.preventDefault();
            navigate("/publisher")
          }}
        >
          <img
            src={require("../assets/images/Link-Building-Software.png")}
            alt="AdverLink"
            style={{ width: "150px" }}
          />
        </a>
        <div className="user-info d-flex justify-content-around">
          <span>Hakan Ertan</span>
          <FontAwesomeIcon
            icon={faUser}
            size={"xl"}
            style={{ color: "#8f8f8f" }}
          />
          <FontAwesomeIcon
            icon={faBell}
            size={"xl"}
            style={{ color: "#8f8f8f" }}
          />
          <FontAwesomeIcon
            icon={faGear}
            size={"xl"}
            style={{ color: "#8f8f8f" }}
          />
        </div>
      </nav>
    </>
  );
};
export default Navbar;
