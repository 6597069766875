import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import "./PublisherStep1.css";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Popularity from "./Popularity";
import Statistics from "./Statistics";
import Theme from "./Theme";
import BasicData from "./BasicsData";
import { Col } from "reactstrap";

const PublisherStep1 = () => {
  const [formData, setFormData] = useState({
    basicData: {},
    themeData: {},
    popularityData: {},
    statisticsData: {},
  });
  const [activeTab, setActiveTab] = useState("basic");
  const navigate = useNavigate();
  const updateData = (newData) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${activeTab}Data`]: newData,
    }));
  };

  const validateCurrentTab = () => {
    const currentData = formData[`${activeTab}Data`];
    switch (activeTab) {
      case "basic":
        if (!currentData.domain) {
          toast.error("Please fill Domain");
          return false;
        }
        if (!currentData.language) {
          toast.error("Please select Language");
          return false;
        }
        if (!currentData.country) {
          toast.error("Please select Country");
          return false;
        }
        if (!currentData.websiteType) {
          toast.error("Please select websiteType");
          return false;
        }
        if (!currentData.price) {
          toast.error("Please select price");
          return false;
        }
        return true;

      case "theme":
        if (!currentData.description) {
          toast.error("Please fill Description");
          return false;
        }
        if (!currentData.language) {
          toast.error("Please fill Language");
          return false;
        }
        // if (!currentData.acceptedCategories.length) {
        //   toast.error("Please select Keywords");
        //   return false;
        // }
        // if (!currentData.blockedWebsites) {
        //   toast.error("Please fill Blocked websites");
        //   return false;
        // }
        return true;
      case "popularity":
        if (!currentData.uniqueUsers) {
          toast.error("Please fill Users");
          return false;
        }
        // if (!currentData.file) {
        //   toast.error("Please select File");
        //   return false;
        // }
        return true;
      default:
        return true;
    }
  };

  const onNext = () => {
    if (validateCurrentTab()) {
      if (activeTab === "basic") {
        setActiveTab("theme");
      } else if (activeTab === "theme") {
        setActiveTab("popularity");
      } else if (activeTab === "popularity") {
        setActiveTab("statistics");
      }
    }
  };

  const onBack = () => {
    if (activeTab === "statistics") {
      setActiveTab("popularity");
    } else if (activeTab === "popularity") {
      setActiveTab("theme");
    } else if (activeTab === "theme") {
      setActiveTab("basic");
    }
  };
  const onFinalize = async () => {
    try {
      const data = new FormData();
  
      data.append("Domain", formData.basicData.Domain);
      data.append("Language", formData.basicData.Language);
      data.append("Country", formData.basicData.Country);
      data.append("WebsiteType", formData.basicData.WebsiteType);
      data.append("Indexing", formData.basicData.Indexing);
      data.append("Price", formData.basicData.Price);
      data.append("Description", formData.basicData.Description);
      data.append("AcceptedCategories", JSON.stringify(formData.basicData.AcceptedCategories));
      data.append("BlockedWebsites", formData.basicData.BlockedWebsites);
      data.append("UniqueUsers", formData.basicData.UniqueUsers);
      data.append("TrustFlow", formData.basicData.TrustFlow);
      data.append("URMedium", formData.basicData.URMedium);
      data.append("Dofollow", formData.basicData.Dofollow);
      data.append("TrafficTracking", formData.basicData.TrafficTracking);
      data.append("QualitativeEvaluation", formData.basicData.QualitativeEvaluation);
      data.append("TechnicalEvaluation", formData.basicData.TechnicalEvaluation);
  
      // Append file if it exists
      if (formData.popularityData.file) {
        console.log(formData.popularityData.file);
        data.append("VisitorStatistics", formData.popularityData.file);
      }
  
      // Log the FormData entries for debugging
      for (let pair of data.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      console.log(data);
      // const response = await axios.post(
      //   "https://adverlinkfrontend20240516123335.azurewebsites.net/api/userdomains/submit", 
      //   data, 
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );

      const response = await fetch(
        'https://adverlinkfrontend20240516123335.azurewebsites.net/api/userdomains/submit',
        {
            method: 'POST',
            body: formData,
        })

      console.log(response);
      toast.success("Data submitted successfully");
      navigate("/publisher-step2");
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data");
    }
  };
  

  const renderActiveTab = () => {
    switch (activeTab) {
      case "theme":
        return <Theme data={formData.themeData} updateData={updateData} />;
      case "popularity":
        return (
          <Popularity data={formData.popularityData} updateData={updateData} />
        );
      case "statistics":
        return (
          <Statistics data={formData.statisticsData} updateData={updateData} />
        );
      default:
        return <BasicData data={formData.basicData} updateData={updateData} />;
    }
  };

  return (
    <>
      <Navbar />
      <div className="container2">
        <Sidebar />
        <div className="main mt-0 pt-0">
          <div className="header">
            <h5>Publisher</h5>
          </div>
          <p>
            (website owner, blog owner, corporate websites, personal websites)
          </p>
          <div className="nav-tabs">
            <a
              href="#."
              onClick={(e) => e.preventDefault()}
              className={activeTab === "basic" ? "nav-tab active" : "nav-tab"}
            >
              Basic data
            </a>
            <a
              href="#."
              onClick={(e) => e.preventDefault()}
              className={activeTab === "theme" ? "nav-tab active" : "nav-tab"}
            >
              Theme
            </a>
            <a
              href="#."
              onClick={(e) => e.preventDefault()}
              className={
                activeTab === "popularity" ? "nav-tab active" : "nav-tab"
              }
            >
              Popularity
            </a>
            <a
              href="#."
              onClick={(e) => e.preventDefault()}
              className={
                activeTab === "statistics" ? "nav-tab active" : "nav-tab"
              }
            >
              Statistics
            </a>
          </div>
          <form style={{ width: "70%" }} onSubmit={(e) => e.preventDefault()}>
            {renderActiveTab()}
          </form>
          <div className="form-actions mb-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={onBack}
              disabled={activeTab === "basic"}
            >
              Back
            </button>
            {activeTab === "statistics" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={onFinalize}
              >
                Finalize
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={onNext}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PublisherStep1;
