import React, { useState } from 'react';
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";

const Advertiser = () => {
    const [formData, setFormData] = useState({
        cuvantCheie: '',
        addURL: '',
        exclusivity: false,
        linkType: 'Normal',
        linkType2: 'Normal',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    return (
        <>
            <Navbar />
            <div className="container4">
                <Sidebar />
                <div className="mt-0 ms-4 pt-0">
                    <div className="header">
                        <h5>Advertiser</h5>
                    </div>
                    <p>
                        (you will be able to buy links in already existing native articles - the most valuable ones)
                    </p>

                    <div className='main3'>
                        <h3 className='my-4'>Add your keywords</h3>
                        <div className="form-group">
                            <h6 htmlFor="cuvantCheie">Cuvant Cheie:</h6>
                            <input
                                placeholder='Enter your keyword'
                                type="text"
                                id="cuvantCheie"
                                name="cuvantCheie"
                                value={formData.cuvantCheie}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <h6 htmlFor="addURL">Add your URL:</h6>
                            <input
                                placeholder='Add your URL'
                                type="text"
                                id="addURL"
                                name="addURL"
                                value={formData.addURL}
                                onChange={handleChange}
                            />
                        </div>
                        <div style={{ color: '#8f8f8f' }} className="checkbox-group form-group">
                            <input
                                style={{ width: '20px' }}
                                type="checkbox"
                                id="exclusivity"
                                name="exclusivity"
                                checked={formData.exclusivity}
                                onChange={handleChange}
                            />
                            I want Exclusivity
                        </div>
                        <div className="form-group">
                            <p htmlFor="linkType">Type of link you want:</p>
                            <select
                                className='w-100'
                                id="linkType"
                                name="linkType"
                                value={formData.linkType}
                                onChange={handleChange}
                                required
                            >
                                <option value="Normal">Normal</option>
                                <option value="Option2">Option2</option>
                                <option value="Option3">Option3</option>
                                <option value="Option4">Option4</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <p htmlFor="linkType2">Type of link you want:</p>
                            <select
                                className='w-100'
                                id="linkType2"
                                name="linkType2"
                                value={formData.linkType2}
                                onChange={handleChange}
                                required
                            >
                                <option value="Normal">DA of the website</option>
                                <option value="Option2">Option2</option>
                                <option value="Option3">Option3</option>
                                <option value="Option4">Option4</option>
                            </select>
                        </div>
                        <div className="form-actions mb-3">
                            <button
                                type="button"
                                className="btn btn-primary"
                                // onClick={onBack}
                            >
                                Add your keyword
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Advertiser;
