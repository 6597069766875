import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Publisher.css';
import Navbar from './components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import Sidebar from './components/Sidebar';
const Publisher = () => {
  const navigate = useNavigate();

  const navigateToStep1 = () => {
    navigate('/publisher-step1');
  };

  return (
    <>
      <Navbar />
      <div className="container px-5">
     <Sidebar/>
        <div className="main">
          <h5>Publisher</h5>
          <p>(website owner, blog owner, corporate websites, personal websites)</p>
          <div className="rules mb-3">
            <h5>Rules to subscribe your website:</h5>
            <p> <FontAwesomeIcon icon={faSquareCheck} size='xl' style={{color: "#21c442",marginRight:'3px'}} /> The site must be Indexed in Google</p>
            <p> <FontAwesomeIcon icon={faSquareCheck} size='xl' style={{color: "#21c442",marginRight:'3px'}} /> The authority should be over 10</p>
            <p> <FontAwesomeIcon icon={faSquareCheck} size='xl' style={{color: "#21c442",marginRight:'3px'}} /> Must have original content (articles, tips, news)</p>
          </div>
          <button className="btn btn-primary w-50" onClick={navigateToStep1}>Add your website</button>
        </div>
      </div>
    </>

  );
};

export default Publisher;
