import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import MyLineChart from './components/LineChart';
import {
    Table,
    Badge,
    UncontrolledDropdown,
    DropdownItem,
} from "reactstrap";
import { useAuth } from './authService';
import { useNavigate } from 'react-router-dom';

const PublisherStep2 = () => {
    const [data, setData] = useState([]);
    const {isLoggedIn} = useAuth()
    const navigate = useNavigate();
    const fetchData = async () => {
        try {

            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await axios.get('https://adverlinkfrontend20240516123335.azurewebsites.net/api/userdomains/get', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const jsonData = response.data;

            // Parse JSON strings back into objects if needed
            const parsedData = jsonData.map(item => ({
                basicData: item,
                themeData: item,
                popularityData: item,
                statisticsData: item
            }));
 

            setData(parsedData);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error state or show a notification to the user
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchData();
        } else {
            navigate('/login');
        }
    }, [isLoggedIn, navigate]);

    const ActionButton = () => {
        return (
            <div className="d-flex">
                <UncontrolledDropdown>
                    <div className="d-flex gap-2">
                        <DropdownItem className="text-center">
                            <Badge color="info">Action</Badge>
                        </DropdownItem>
                    </div>
                </UncontrolledDropdown>
            </div>
        );
    };

    return (
        <>
            <Navbar />
            <div className='container3'>
                <Sidebar />
                <div className="main2 ">
                    <div className="header">
                        <h5>Publisher</h5>
                    </div>
                    <p>(website owner, blog owner, corporate websites, personal websites)</p>
                    {data.length > 0 ? (
                        <>
                            <h6 className='my-4'>Keywords in your publisher network</h6>
                            <div className='bg-primary p-2 rounded text-white'>Keyword</div>
                            <div className="table-responsive" style={{ maxHeight: '400px', overflowY: data.length > 10 ? 'scroll' : 'auto' }}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Keyword</th>
                                            <th scope="col">Website</th>
                                            <th scope="col">Monthly income</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Intervention needed</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.themeData && item.themeData.description}</td>
                                                <td>{item.basicData && item.basicData.domain}</td>
                                                <td>{item.basicData && `${item.basicData.price} USD + VAT`}</td>
                                                {/* Adjust status and intervention based on your data structure */}
                                                <td className=''>Paid</td>
                                                <td>{/* Intervention needed value */}</td>
                                                <td>
                                                    <ActionButton />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>
                    ) : (
                        <div className="text-center my-5">
                            <p>No data found.</p>
                        </div>
                    )}
                    <div className='my-5 chartHead'>
                        <h6 className='my-4'>Income last 30 days / link as a service concept</h6>
                        <div className='text-center'>Keyword Evolution vs. Revenue Over 30 Days</div >
                    </div>
                    <MyLineChart />
                </div>
            </div>
        </>
    )
}

export default PublisherStep2;
