import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import {
    Table,
} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const AdvertiserStep1 = () => {
    const initialData = [
        {
            portal: 'apropotv.ro',
            type: 'General Community Portal',
            country: 'Romania',
            theme: 'Gosip tv, monden',
            uniqueUsers: 1050320,
            semRush: 502,
            trustFlow: 56,
            urlRating: 808,
            dofollow: true,
            trafficTracking: false,
            contentQuality: '5/10',
            technicalQuality: '5/10',
            price: '5 USD',
        },
        {
            portal: 'apropotv.ro',
            type: 'General Community Portal',
            country: 'Romania',
            theme: 'Gosip tv, monden',
            uniqueUsers: 1050320,
            semRush: 502,
            trustFlow: 56,
            urlRating: 808,
            dofollow: true,
            trafficTracking: false,
            contentQuality: '5/10',
            technicalQuality: '5/10',
            price: '5 USD',
        },
        {
            portal: 'apropotv.ro',
            type: 'General Community Portal',
            country: 'Romania',
            theme: 'Gosip tv, monden',
            uniqueUsers: 1050320,
            semRush: 502,
            trustFlow: 56,
            urlRating: 808,
            dofollow: true,
            trafficTracking: false,
            contentQuality: '5/10',
            technicalQuality: '5/10',
            price: '5 USD',
        }
    ];

    const [data, setData] = useState(initialData);
    const [filters, setFilters] = useState({
        domainName: '',
        theme: '',
        country: 'Romania',
        typeOfPortal: '',
        region: '',
        minUniqueUsers: '',
        dofollowLinks: false,
        typeOfLinks: '',
        numberOfLinks: '',
        priceFrom: '',
        priceTo: '',
        attractivePrice: false,
        domainAuthority: '',
        portalQuality: '',
        favorites: false
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters({ ...filters, [name]: checked });
    };

    const ArrowSpan = () => {
        return (
            <span className='row h-25'>
                <FontAwesomeIcon icon={faCaretUp} size='m' style={{ color: "black"}} />
                <FontAwesomeIcon icon={faCaretDown} size='m' style={{ color: "black"}} />
            </span>
        );
    };

    return (
        <>
            <Navbar />
            <div className="container5">
                <Sidebar />
                <div className="mt-0 ms-4 subCont5 pt-0">
                    <div className="header">
                        <h5>Advertiser</h5>
                    </div>
                    <p>
                        (you will be able to buy links in already existing native articles - the most valuable ones)
                    </p>
                    <h3 className='mt-4'>Choose a publication</h3>
                    <div className='inputDiv'>
                        <p>Basic filters</p>
                        <h5>Basic filters</h5>
                        <div className="form-group2 d-flex flex-wrap">
                            <input 
                                name='domainName' 
                                placeholder='Domain website name, tag' 
                                type="text" 
                                value={filters.domainName}
                                onChange={handleInputChange} 
                            />
                            <select 
                                name='theme' 
                                value={filters.theme}
                                onChange={handleInputChange}
                            >
                                <option selected>Theme</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <select 
                                name='country' 
                                value={filters.country}
                                onChange={handleInputChange}
                            >
                                <option selected>Romania</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <select 
                                name='typeOfPortal' 
                                value={filters.typeOfPortal}
                                onChange={handleInputChange}
                            >
                                <option selected>Type of portal</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <select 
                                name='region' 
                                value={filters.region}
                                onChange={handleInputChange}
                            >
                                <option selected>Region</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <input 
                                name='minUniqueUsers' 
                                placeholder='Min. unique users' 
                                type="text" 
                                value={filters.minUniqueUsers}
                                onChange={handleInputChange} 
                            />
                            <span className='d-flex justify-content-between'>
                                <p>Dofollow links</p>
                                <input 
                                    name='dofollowLinks' 
                                    className='h-100' 
                                    type="checkbox" 
                                    checked={filters.dofollowLinks}
                                    onChange={handleCheckboxChange} 
                                />
                            </span>
                            <select 
                                name='typeOfLinks' 
                                value={filters.typeOfLinks}
                                onChange={handleInputChange}
                            >
                                <option selected>Type of Links</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <select 
                                name='numberOfLinks' 
                                value={filters.numberOfLinks}
                                onChange={handleInputChange}
                            >
                                <option selected>Number of links in the co..</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <span className='d-flex px-0 py-0 justify-content-between gap-3 border-0'>
                                <input 
                                    name='priceFrom' 
                                    className='w-50 border rounded px-2' 
                                    placeholder='Price from' 
                                    type="text" 
                                    value={filters.priceFrom}
                                    onChange={handleInputChange} 
                                />
                                <input 
                                    name='priceTo' 
                                    className='w-50 border rounded px-2' 
                                    placeholder='Price to' 
                                    type="text" 
                                    value={filters.priceTo}
                                    onChange={handleInputChange} 
                                />
                            </span>
                            <span className='d-flex justify-content-between'>
                                <p>Attractive price</p>
                                <input 
                                    name='attractivePrice' 
                                    className='h-100' 
                                    type="checkbox" 
                                    checked={filters.attractivePrice}
                                    onChange={handleCheckboxChange} 
                                />
                            </span>
                            <input 
                                name='domainAuthority' 
                                placeholder='Domain Authority' 
                                type="text" 
                                value={filters.domainAuthority}
                                onChange={handleInputChange} 
                            />
                            <select 
                                name='portalQuality' 
                                value={filters.portalQuality}
                                onChange={handleInputChange}
                            >
                                <option selected>Portal quality</option>
                                <option value="Option2">Option2</option>
                            </select>
                            <span className='d-flex justify-content-between'>
                                <p>Favorites</p>
                                <input 
                                    name='favorites' 
                                    className='h-100' 
                                    type="checkbox" 
                                    checked={filters.favorites}
                                    onChange={handleCheckboxChange} 
                                />
                            </span>
                        </div>
                    </div>
                    <div className="form-actions1 mb-3">
                        <button type="button" className="btn btn-primary">Search</button>
                        <button type="button" className="btn btn-primary">Advanced Filters</button>
                        <button type="button" className="btn btn-primary">Saved Searches</button>
                    </div>
                    <div className='adverTable'>
                    <div className="table-responsive">
                            <Table>
                                <thead style={{ verticalAlign: 'middle' }}>
                                    <tr>
                                        <th scope="col">Portal
                                            <span className='d-flex justify-content-between'>
                                                <small style={{ fontWeight: 'lighter' }}>
                                                    Type <br />
                                                    Country: Region<br />
                                                    Theme
                                                </small>
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Unique Users
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                SemRush
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Trust Flow
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Average <br />
                                                URL Rating
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Dofollow
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Traffic<br />
                                                Tracking
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Content<br />
                                                Quality
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Technical<br />
                                                Quality
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                        <th style={{ borderWidth: '1px !important' }}>
                                            <span className='d-flex justify-content-between'>
                                                Price<br />
                                                /Price
                                                <ArrowSpan />
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((entry, index) => (
                                        <tr key={index}>
                                            <td className='border-right align-middle'>
                                                <h5>{entry.portal}</h5>
                                                <small>
                                                    {entry.type}: {entry.country} <br /> {entry.theme}
                                                </small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.uniqueUsers}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.semRush}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.trustFlow}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.urlRating}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <FontAwesomeIcon icon={entry.dofollow ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.dofollow ? "#21c442" : "red", marginRight: '3px' }} />
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <FontAwesomeIcon icon={entry.trafficTracking ? faCircleCheck : faCircleXmark} size='l' style={{ color: entry.trafficTracking ? "#21c442" : "red", marginRight: '3px' }} />
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.contentQuality}</small>
                                            </td>
                                            <td className='border align-middle text-center'>
                                                <small>{entry.technicalQuality}</small>
                                            </td>
                                            <td className='border-left align-middle text-center'>
                                                <small className='me-2'>{entry.price}</small>
                                                <button className=' mt-0 btn btn-primary btn-sm'>Order Now</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdvertiserStep1;
