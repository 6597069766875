import React, { useEffect, useState } from 'react';


const Sidebar = () => {
    const [isSidebarVisible, setSidebarVisible] = useState(false);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };

    return (
        <>
            <div className={`sidebar ${isSidebarVisible ? 'show' : 'hidden'}`}>
                <strong>My Portals</strong>
                <a href="#" className='text-decoration-none my-3'>Websites</a>
                <a href="/publisher-step2" className='text-decoration-none mb-3'>Links reports</a>
                <hr />
                <strong>My Account</strong>
                <a href="#" className='text-decoration-none my-3'>My account</a>
                <a href="#" className='text-decoration-none mb-3'>Platform language</a>
                <a href="#" className='text-decoration-none mb-3'>Users</a>
                <a href="#" className='text-decoration-none mb-3'>Billing</a>
                <a href="#" className='text-decoration-none mb-3'>Invoices</a>
                <strong>Wordpress Plugin</strong>
                <a href="#" className='text-decoration-none my-3'>Download</a>
                <a href="#" className='text-decoration-none mb-3'>How to use it</a>
            </div>
            <button 
                style={{ borderRadius: '50%' }} 
                className="sidebar-toggle-button" 
                onClick={toggleSidebar}
            >
                {isSidebarVisible ? '<' : '>'}
            </button>
        </>
    );
};

export default Sidebar;