import React, { useEffect, useState } from 'react';


const BasicData = ({ data, updateData }) => {
  const [formData, setFormData] = useState(data); 

  useEffect(() => {
    // Check if formData is valid and has changed from the initial data
    if (JSON.stringify(formData) !== JSON.stringify(data)) {
      updateData(formData);
    }
  }, [formData, data, updateData]);
  

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  
  return (
    <div style={{ width: '130%' }}>
      <div className="form-group">
        <p htmlFor="domain">Domain *</p>
        <input
          placeholder='Enter your Domain'
          type="text"
          id="domain"
          name="domain"
          value={formData.domain || ''}
          onChange={handleChange}
          required
        />
        
      </div>
      <div className="form-group">
        <p htmlFor="language">Language of publication *</p>
        <select className='w-100' id="language" name="language" value={formData.language || ''} onChange={handleChange} required>
          {/* {languages.map((language) => (
                <option key={language} value={language}>{language}</option>
                ))} */}
          <option value="" selected>Choose your language</option>
          <option value="Bulgarian">Bulgarian</option>
          <option value="Croatian">Croatian</option>
          <option value="Czech">Czech</option>
          <option value="Danish">Danish</option>
          <option value="Dutch">Dutch</option>
          <option value="English">English</option>
          <option value="Estonian">Estonian</option>
          <option value="Finnish">Finnish</option>
          <option value="French">French</option>
          <option value="German">German</option>
          <option value="Greek">Greek</option>
          <option value="Hungarian">Hungarian</option>
          <option value="Irish">Irish</option>
          <option value="Italian">Italian</option>
          <option value="Latvian">Latvian</option>
          <option value="Lithuanian">Lithuanian</option>
          <option value="Maltese">Maltese</option>
          <option value="Polish">Polish</option>
          <option value="Portuguese">Portuguese</option>
          <option value="Romanian">Romanian</option>
          <option value="Slovak">Slovak</option>
          <option value="Slovenian">Slovenian</option>
          <option value="Spanish">Spanish</option>
        </select>
      </div>
      <div className="form-group">
        <p htmlFor="country">Country *</p>
        <select id="country" name="country" value={formData.country || ''} onChange={handleChange} required>
          {/* {countries.map((country) => (
              <option key={country} value={country}>{country}</option>
              ))} */}
          <option style={{ color: '#8f8f8f' }} value="" selected >Country of Origin</option>

          <option value="Austria">Austria</option>
          <option value="Belgium">Belgium</option>
          <option value="Bulgaria">Bulgaria</option>
          <option value="Republic of Cyprus">Republic of Cyprus</option>
          <option value="Czech republic">Czech republic</option>
          <option value="Denmark">Denmark</option>
          <option value="Estonia">Estonia</option>
          <option value="Finland">Finland</option>
          <option value="France">France</option>
          <option value="Germany">Germany</option>
          <option value="Greece">Greece</option>
          <option value="Hungary">Hungary</option>
          <option value="Ireland">Ireland</option>
          <option value="Italy">Italy</option>
          <option value="Latvia">Latvia</option>
          <option value="Lithuania">Lithuania</option>
          <option value="Luxembourg">Luxembourg</option>
          <option value="Malta">Malta</option>
          <option value="Netherlands">Netherlands</option>
          <option value="Poland">Poland</option>
          <option value="Portugal">Portugal</option>
          <option value="Romania">Romania</option>
          <option value="Slovakia">Slovakia</option>
          <option value="Slovenia">Slovenia</option>
          <option value="Spain">Spain</option>
          <option value="Sweden">Sweden</option>
        </select>
      </div>
      <div className="form-group">
        <p htmlFor="website-type">Type of website *</p>
        <select id="website-type" name="websiteType" value={formData.websiteType || ''} onChange={handleChange} required>
          {/* {websiteTypes.map((type) => (
            <option key={type} value={type}>{type}</option>
            ))} */}
          <option selected >Choose your website type</option>
          <option value="Blog">Blog</option>
          <option value="General Community Portal-countrywide">General Community Portal-countrywide</option>
          <option value="Thematic portal-countrywide">Thematic portal-countrywide</option>
          <option value="General Community Portal-regional">General Community Portal-regional</option>
          <option value="Thematic portal-regional">Thematic portal-regional</option>
          <option value="Corporate website">Corporate website</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <div style={{ color: '#8f8f8f' }} className="checkbox-group form-group">
        <input style={{ width: '20px' }} type="checkbox" id="indexing" name="indexing" checked={formData.indexing || false} onChange={handleChange} />
        YES, I hereby grant explicit permission for the AdverLink Bot to crawl this website.
      </div>
      <div className="form-group">
        <p htmlFor="price">Price per link *</p>
        <select id="price" name="price" value={formData.price || ''} onChange={handleChange} required>
          <option value="" selected>Please select your price</option>
          <option value="2">2</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
          <option value="35">35</option>
          <option value="45">45</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="300">300</option>
          <option value="400">400</option>
        </select>
      </div>
    </div>
  );
}

export default BasicData;