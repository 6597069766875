import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './authService'; // Ensure this is your auth service import
import './Register.css';
import logoImage from './assets/images/Link-Building-Software.png';
import googleLogo from './assets/images/google-logo.png';

const Register = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    userType: '',
    news: false,
  });

  const [messages, setMessages] = useState({
    error: '',
    success: '',
  });

  const { register } = useAuth(); // Ensure you have register method in your auth service
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // await register(formData);
      // setMessages({ success: 'Account created successfully!', error: '' });
      navigate('/emailverify'); // Redirect to home page
    } catch (error) {
      setMessages({
        error: 'This email address is already in use. Try a different email address or restore your password.',
        success: '',
      });
    }
  };

  return (
    <div className="register-container">
      <div className="left-side ">
        <div className="logo mb-5">
          <img src={logoImage} alt="AdverLink" />
        </div>
        <h2 className='my-4'>Create your account</h2>
        <button className="custom-google-btn">
          <img src={googleLogo} alt="Google Logo" className="google-logo" />
          Continue with Google
        </button>
        <div className="separator">
          <hr className="separator-line " />
          <span className="separator-text">or</span>
          <hr className="separator-line" />
        </div>

        <div className="messages">
          {messages.error && <div className="error-message">{messages.error}</div>}
          {messages.success && <div className="success-message">{messages.success}</div>}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="form-group tooltip-container">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              // required
              placeholder='Enter your email'
            />

          </div>
          <div className="form-group tooltip-container">
            <div className='d-flex justify-content-between pe-2'>
              <label htmlFor="password">Password:</label>
              <p class="font-italic">𝒊</p>
            </div>
            <input
              style={{ marginTop: '5px' }}
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              // required
              placeholder='Enter your password'
            />
            <span className="tooltiptext">Secure password tips: More than 8 characters, Both lowercase and uppercase letters, At least one numeric character</span>
          </div>
          <div className="form-group tooltip-container">
            <div className='d-flex justify-content-between pe-2'>
              <label className='w-50' htmlFor="userType">User type:</label>
              <p class="font-italic">𝒊</p>
            </div>
            <select
              style={{ maxWidth: '100%', marginTop: '5px' }}
              id="userType"
              name="userType"
              value={formData.userType}
              onChange={handleChange}
              // required
            >
              <option value="" disabled>Select user type</option>
              <option value="advertiser">Advertiser - Link buyer</option>
              <option value="publisher">Publisher - Link seller</option>
            </select>

            <span className="tooltiptext">Choose Advertiser if you are looking to buy links, choose Publisher if you are looking to sell links</span>
          </div>
          <div className="form-group checkbox">
            <input
              type="checkbox"
              id="news"
              name="news"
              checked={formData.news}
              onChange={handleChange}
            />
            <small htmlFor="news">Receive AdverLink news and SEO link building tips</small>
          </div>
          <button type="submit" className="btn btn-primary">Create account</button>
        </form>
        <div className="form-footer">
          Already have an account? <a href="/login">Log in</a>
        </div>
        <small className="form-footer">
          By creating your account, you agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
        </small>
      </div>
    </div>
  );
};

export default Register;
