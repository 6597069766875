import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const apiUrl = 'https://adverlinkfrontend20240516123335.azurewebsites.net/api/authentication/';
const baseApiUrl = 'https://adverlinkfrontend20240516123335.azurewebsites.net/';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true); 
      console.log('token');
    }
  }, []);

  const register = async (registerRequest) => {
    try {
      await axios.post(`${apiUrl}register`, registerRequest);
      navigate('/login');
    } catch (error) {
      console.error('Registration failed:', error);
      throw error;
    }
  };

  const login = async (loginRequest) => {
    try {
      const response = await axios.post(`${apiUrl}login`, loginRequest);
      localStorage.setItem('token', response.data.token);
      setIsLoggedIn(true);
      navigate('/publisher');
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      await axios.post(`${apiUrl}reset-password`, { email });
    } catch (error) {
      console.error('Password reset failed:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await axios.post(`${apiUrl}logout`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      navigate('/login');
    }
  };

  const googleSignIn = async (idToken) => {
    console.log('googleSignIn called with token:', idToken);
    try {
      const formData = new FormData();
formData.append('credential', idToken);
      const response =  await axios.post(`${apiUrl}google-signin`,formData, {
        headers: {
          'Content-Type': 'multipart/form-data'  // Set the correct content type for form data
        }
      });
      console.log(response);
      localStorage.setItem('token', response.data.token);
      const payload = jwtDecode(response.data.token);
      setUser({'email':payload.sub})
      setIsLoggedIn(true);

      const getUserTypeResponse = await axios.get(baseApiUrl+'api/User/email/' + payload.sub);
      console.log(getUserTypeResponse);
      if (getUserTypeResponse.data)
        navigate('/' + getUserTypeResponse.data);
      else
        navigate('/choosetype');

    } catch (error) {
      console.error('Google sign-in failed:', error);
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, isLoggedIn, register, login, resetPassword, logout, googleSignIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);