import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './authService';
import Login from './Login';
import Publisher from './Publisher';
import PublisherStep1 from './PublisherStep1';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import EmailVerification from './EmailVerification';
import PublisherStep2 from './PublisherStep2';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Advertiser from './Advertiser';
import AdvertiserStep1 from './AdvertiserStep1';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ChooseUserType from './ChooseUserType';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/publisher-step2" element={<PublisherStep2 />} />
          <Route path="/publisher-step1" element={<PublisherStep1 />} />
          <Route path="/advertiser" element={<Advertiser />} />
          <Route path="/advertiser-step1" element={<AdvertiserStep1 />} />
          <Route path="/publisher" element={<Publisher />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/emailverify" element={<EmailVerification />} />
          <Route path="/chooseType" element={<ChooseUserType/>}/>
          <Route path="/" element={<Login />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
